import PropTypes from "prop-types"
import React from "react"
import { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Checkout } from "./Checkout"
import { ClassOption } from "./ClassOption"
import { PriceInfo } from "./PriceInfo"
import { Frame, Stack } from "framer"
import useStore from "../../datas/useStore"
import { getParameterByName, commaPipe } from "ruucm-util"
import Countdown from "react-countdown-now"
import {
  CLASS_OPTIONS,
  CURRENT_PERIOD,
  CURRENT_NUMBER_OF_THE_PRODUCTS,
  deadLineSuperEarlyBird,
  deadLineEarlyBird,
  deadLineFinal,
  hfOpened,
  SUPER_EARLYBIRD_DESCRIPTION,
  EARLYBIRD_DESCRIPTION,
  OPENSALE_PRICE,
} from "../../shared/classConsts/hello-framer-x"
import { preventScrollingBehindFixedOverlay } from "../../shared/utils"
import PurchaseSection from "./PurchaseSection"
import { motion } from "framer-motion"

var Scroll30 = false
var Scroll50 = false
var Scroll60 = false
var Scroll90 = false

const FixedWrap = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
`
const Content = styled(motion.div)`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f5f7fa;
  pointer-events: all;
  /* enable smooth scroll */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -5px 15px 0px;
  width: 100%;
`
const Button = styled.div`
  background: #fdd388;
  cursor: pointer;
  text-align: center;
  height: 80px;
  padding: 10px 0;
  position: relative;
`
const Notice = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  color: #e9484d;
  span {
    font-size: 12px;
    opacity: 0.7;
  }
`
const BuyText = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 28px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  span {
    font-family: "NanumSquare";
    font-weight: 700;
    font-size: 18px;
    vertical-align: middle;
  }
  ${props =>
    props.hfOpened === false &&
    css`
      opacity: 0.3;
    `}
`

const ClassOptions = styled(Frame)`
  width: 375px !important;
  height: 375px !important;
  border-radius: 6px;
`

const Wrap = styled(Stack)`
  width: 335px !important;
  height: 291px !important;
`

const Text1 = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  text-align: left;
`
const Text2 = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 16px;
  text-align: right;
`
const ClassTypeText = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  text-align: right;
`
var temp = 0
var delta = 0
var initiateCheckout = false
var initialHeight,
  urlBarAppeared = true

const PurchaseArea = ({ ...props }) => {
  const [opened, setOpened] = useState(false)
  const [show, setShow] = useState(true)
  const [scrollAmount, setScrollAmount] = useState(0)
  // const [urlBarAppeared, setUrlBarAppeared] = useState(true)
  const [classOption, setClassOption] = useStore()

  useEffect(() => {
    initialHeight = window.innerHeight

    setTimeout(() => {
      setClassOption({
        showCouponNotification: true,
      })
    }, 1000)

    window.addEventListener("scroll", e => {
      e.preventDefault()
      // return false
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      // console.log(scrollTop)
      delta = scrollTop - temp
      temp = scrollTop

      if (scrollTop > 3000 && Scroll30 == false) {
        console.log("30%")
        Scroll30 = true
        // track ViewContent (fb pixel)
        if (typeof window !== "undefined" && window.fbq != null) {
          window.fbq("track", "ViewContent")
          window.fbq("trackCustom", "Scroll30")
          // setClassOption({
          //   showCouponNotification: true,
          // })
        }
      } else if (scrollTop > 10000 && Scroll60 == false) {
        console.log("60%")
        Scroll60 = true
        // track ViewContent (fb pixel)
        if (typeof window !== "undefined" && window.fbq != null)
          window.fbq("trackCustom", "Scroll60")
      } else if (scrollTop > 14564 && Scroll90 == false) {
        console.log("90%")
        Scroll90 = true
        if (typeof window !== "undefined" && window.fbq != null)
          window.fbq("trackCustom", "Scroll90")
      }

      if (delta > 0 && urlBarAppeared)
        // scroll down
        setShow(false)
      // scroll up
      else if (delta < 0) setShow(true)
    })

    //attach event touchend
    window.addEventListener("resize", e => {
      var w = e.target
      urlBarAppeared = false
      console.log("URL bar Disappeared!!")
      var newHeight = w.innerHeight
      if (newHeight <= initialHeight) {
        console.log("URL bar APPEARED!!")
        setTimeout(() => {
          urlBarAppeared = true
        }, 1000)
      }
    })
  }, [])
  // console.log("opened", opened)
  console.log("scrollAmount", scrollAmount)
  return (
    <FixedWrap
      onClick={() => {}} // To enable pointer-events (scroll)
      id="purchase-area"
      style={{
        height: opened ? "80vh" : 80,
        pointerEvents: "all",
        scrollBehavior: "smooth",
        WebkitOverflowScrolling: "touch",
      }}
      onScroll={e => setScrollAmount(e.target.scrollTop)}
    >
      <Content
        width="100%"
        transition={{
          ease: "easeInOut",
        }}
        animate={{
          height: opened ? "80vh" : 80,
        }}
      >
        <Button
          onMouseDown={() => {
            if (hfOpened) {
              // resolve scroll issue
              // preventScrollingBehindFixedOverlay("purchase-area")
              if (!opened) {
                // open!
                document.body.style.overflow = "hidden"
                setOpened(true)
                // track InitiateCheckout (fb pixel)
                if (
                  typeof window !== "undefined" &&
                  window.fbq != null &&
                  !initiateCheckout
                ) {
                  window.fbq("track", "InitiateCheckout", {
                    content_type: "hello-framer-x",
                  })
                  initiateCheckout = true
                  console.log("initiateCheckout!")
                }
              } else {
                // close!
                document.body.style.overflow = "initial"
                setOpened(false)
              }
            } else {
              // Alert for Notification for next class
              if (
                typeof window !== "undefined" &&
                window.confirm(
                  "온라인 하버스쿨에서 이제 프레이머를 배워 보실 수 있습니다.\n(죄송합니다. 현재 Hello, Framer X 8기 모든 자리가 마감 되었습니다.)\n\n확인 버튼을 누르시면 새로운 하버스쿨로 이동합니다."
                )
              )
                window.open("https://link.harbor.school/old-to-new")
            }
          }}
        >
          {hfOpened && (
            <Notice
              width="100%"
              height=""
              background=""
              initial={{
                left: -200,
                opacity: 0,
              }}
              animate={{
                left: 0,
                opacity: 1,
              }}
              transition={{
                delay: 1,
              }}
              dangerouslySetInnerHTML={{
                // __html: CURRENT_NUMBER_OF_THE_PRODUCTS.description,
                __html: `${CURRENT_PERIOD.title} / 월 ${commaPipe(
                  (classOption.total - classOption.couponDiscount) / 6
                )} <span>(6개월 할부 시)</span>`,
              }}
            />
          )}
          {!hfOpened && (
            <Notice
              width="100%"
              height=""
              background=""
              initial={{
                left: -200,
                opacity: 0,
              }}
              animate={{
                left: 0,
                opacity: 1,
              }}
              transition={{
                delay: 1,
              }}
              dangerouslySetInnerHTML={{
                __html: `모집 마감 / 감사합니다`,
              }}
            />
          )}
          <BuyText hfOpened={hfOpened}>
            {opened ? "닫기" : "구매하기"}{" "}
            {hfOpened && (
              <span>
                (할인 마감{" "}
                <Countdown
                  date={(() => {
                    switch (CURRENT_PERIOD.description) {
                      case SUPER_EARLYBIRD_DESCRIPTION:
                        return deadLineSuperEarlyBird
                      case EARLYBIRD_DESCRIPTION:
                        return deadLineEarlyBird
                      default:
                        return deadLineFinal
                        break
                    }
                  })()}
                />
                후 종료)
              </span>
            )}
          </BuyText>
        </Button>
        {typeof window !== "undefined" && (
          <PurchaseSection scrollAmount={scrollAmount} />
        )}
      </Content>
    </FixedWrap>
  )
}

export default PurchaseArea
