import * as React from "react"
import { useState } from "react"
import styled, { css } from "styled-components"
import useStore from "../../datas/useStore"
import { motion } from "framer-motion"

const Wrap = styled(motion.div)`
  background: #fdd388;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
`
const Align = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const Text = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
`
const ApplyButton = styled.button`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  border-radius: 2px;
  height: 50px;
  line-height: 50px;
  padding: 0 24px;
  cursor: pointer;
  display: block;
  color: #2348a7;
  background: white;
  border: 1px solid #2348a7;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
`

var discount = 30000
// component
export const CouponButton = ({ onClick, ...props }) => {
  const [classOption, setClassOption] = useStore()
  const [applied, setApplied] = useState(false)
  return (
    <Wrap
      animate={{
        opacity: applied ? 0 : 1,
        marginTop: applied ? 150 : 0,
      }}
      transition={{
        duration: 0.36,
      }}
    >
      <Align>
        <Text>모집마감 깜짝 할인쿠폰 🎉</Text>
        <ApplyButton
          onClick={() => {
            alert("8기 모집 마감기념 [3만원 할인 쿠폰] 이\n적용 되었습니다")
            setClassOption({
              total: classOption.total - discount,
            })
            setApplied(true)
          }}
        >
          적용 하기
        </ApplyButton>
      </Align>
    </Wrap>
  )
}
