import * as React from "react"
import { useState } from "react"
import styled, { css } from "styled-components"
import { Field, reduxForm } from "redux-form"

import validate from "./validate"
import validatePG from "./validatePG"
import { FixedCreditCardInput } from "./FixedCreditCardInput"
import { OrderComplete } from "./OrderComplete"
import { InstallmentOrderComplete } from "./InstallmentOrderComplete"
import {
  createOrder,
  createPGOrder,
  createInstallmentOrder,
} from "../../../handlers"
import useStore from "../../../datas/useStore"
import { useBeforeUnload } from "./use-before-unload"
import { colors } from "../../../shared/consts"

const Wrap = styled.div`
  width: 100%;
`

const Card = styled.div`
  box-shadow: 5px 10px 30px rgba(41, 26, 204, 0.122707);
  /* border-radius: 6px; */
  position: relative;
  padding: 40px 20px 340px 20px;
  ${props =>
    props.primary &&
    css`
      background: #2348a7;
      ${Title} {
        color: #ffffff;
      }
    `};
  ${props =>
    props.refund &&
    css`
      height: 100%;
    `};
`

const Title = styled.div`
  font-family: "NanumSquare";
  font-weight: 800;
  line-height: 1.25;
  font-size: 18px;
  letter-spacing: -0 0.8px;
  color: #4a4a4a;
  margin-bottom: 11px;
`

const Button = styled.button`
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 24px;
  display: inline-block;
  cursor: pointer;
  color: #2348a7;
  background: white;
  border: 1px solid #2348a7;
  position: relative;
  outline: none;
  transition: 0.28s all ease-in-out;
  width: 50vw;
  ${props =>
    props.type === "submit" &&
    css`
      margin-top: 128px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: initial;
      border-radius: 2px;
    `}
  ${props =>
    props.active &&
    css`
      background: #2348a7;
      color: white;
    `}
`
const Loading = styled.div`
  font-family: "NanumSquare";
  padding: 80px;
  font-size: 25px;
  font-weight: 900;
  strong {
    color: ${colors.support01};
    font-size: 18px;
  }
`

// redux-form
const Form = styled.form``
const RederFieldLabel = styled.label`
  font-family: "NanumSquare";
  font-weight: 400;
  min-width: 97px;
  font-size: 12px;
  letter-spacing: -0.825001px;
  vertical-align: top;

  color: #ffffff;
  display: inline-block;
`

const RederFieldInput = styled.input`
  line-height: 50px;
  height: 50px;

  background: #ffffff;
  border-radius: 2px;
  display: inline-block;
  border: none;
  color: #000000;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #757575;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #757575;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #757575;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #757575;
  }

  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 30px;

  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `}
  ${props =>
    props.width &&
    css`
      width: props.widthpx;
    `}
`
const RederCheckoutFieldWrap = styled.span`
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 1px;
  background: white;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #2b1ecd;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const RederCheckoutFieldInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 0;
  z-index: 1;

  &[value="true"] {
    ~ ${Checkmark}:after {
      display: block;
    }
  }
`

const RederCheckoutFieldLabel = styled.label`
  font-family: "NanumSquare";
  font-weight: 400;
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  margin-left: 9px;
  vertical-align: middle;
  letter-spacing: -0.825001px;

  color: #ffffff;
`

const CreditCardInputWrap = styled.div`
  padding-bottom: 138px;
  font-family: "NanumSquare";
  .checkout-card-input {
    height: 50px;
    line-height: 50px;
  }
  #cvc {
    visibility: hidden;
  }
`
const InputTopLabel = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.825001px;
  color: #ffffff;
  margin-bottom: 12px;
`
const CreditSaveInfo = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  line-height: 14px;
  font-size: 12px;
  letter-spacing: -0.825001px;

  color: #ffffff;
  margin-top: 15px;
`
const InflowFields = styled.div`
  padding-top: 138px;
  width: 400px;
`

const ErrorWrapper = styled.div`
  font-family: "NanumSquare";
  color: #ee4230;
  text-align: center;
  margin-top: 20px;
`

const renderField = ({
  input,
  label,
  placeholder,
  type,
  width,
  nomargin,
  meta: { touched, error },
}) => (
  <div>
    {label && <RederFieldLabel>{label}</RederFieldLabel>}

    <RederFieldInput
      {...input}
      placeholder={placeholder}
      type={type}
      width={width}
      nomargin={nomargin}
    />
  </div>
)

const checkoutRenderField = ({
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <RederCheckoutFieldWrap>
    <RederCheckoutFieldInput {...input} type={type} />
    <Checkmark />
    {label && <RederCheckoutFieldLabel>{label}</RederCheckoutFieldLabel>}
  </RederCheckoutFieldWrap>
)

// fb event
// var fbqLockAddPaymentInfo = false

// timer
var setIntervalLock = false
var showChatted = false
var sec = 0
// const limitTime = 30
const limitTime = 20 // unuse

// component

const CheckoutFormComp = ({
  // from parent
  setShowDetails,
  setShowChat,
  manual,
  installment,

  // local
  startCounter,

  // redux-form
  history,
  error,
  pristine,
  reset,
  handleSubmit,
  submitting,

  ...props
}) => {
  const [fbqLockAddPaymentInfo, setFbqLockAddPaymentInfo] = useState(false)
  console.log("fbqLockAddPaymentInfo", fbqLockAddPaymentInfo)
  useBeforeUnload(fbqLockAddPaymentInfo)
  const [showRefundInfo, setShowRefundInfo] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState("card-number")
  // card-input
  const [cardNumber, setCardNumber] = useState("")
  const [expiry, setExpiry] = useState("")
  // order
  const [orderState, setOrderState] = useState(0)
  const [orderInfo, setOrderInfo] = useState("")
  const [classOption, setClassOption] = useStore()

  const makeOrderData = values => {
    const discountedTotal = classOption.total - classOption.couponDiscount

    return [
      {
        pay_method: "nice",
        name: values.username,
        phone: values.phone,
        email: values.email,
        cardNumber: cardNumber.replace(" ", "-"),
        expiry: "20" + expiry.slice(-2) + "-" + expiry.substring(0, 2),
        cardowner: values.cardowner,
        cardpw: values.cardpw,
        type: classOption.selected,
        inflowFbPage: values["inflow-fb-page"],
        inflowFbAd: values["inflow-fb-ad"],
        inflowInstaAd: values["inflow-insta-ad"],
        inflowNewsletter: values["inflow-newsletter"],
        inflowKakao: values["inflow-kakao"],
        inflowFriend: values["inflow-friend"],
        inflowEtc: values["inflow-etc"],
      },
      discountedTotal,
      !manual || installment > 1, // isSubscription
      setOrderState,
      finalResult => {
        // console.log("finalResult", finalResult)
        fbq("track", "Purchase", {
          value: discountedTotal,
          currency: "KRW",
          content_name: "hello-framer-x",
          content_detail:
            finalResult.buyer_name +
            "-" +
            finalResult.apply_num +
            "-" +
            finalResult.buyer_tel,
        })

        // alert('주문이 완료 되었습니다 감사합니다!')
        setOrderInfo(finalResult)
      },
    ]
  }

  return (
    <Wrap>
      <div col="12">
        {(() => {
          switch (orderState) {
            case 1:
            case 2:
            case 3:
            case 4:
              // return <Loading width="100%" />
              return (
                <Loading>
                  주문 생성중...
                  <br />
                  <br />
                  <strong>
                    (주문 완료 팝업이 나올 때까지 창을 닫지 말아 주세요)
                  </strong>
                </Loading>
              )
            case 5:
              return <OrderComplete orderInfo={orderInfo} />
            case 6:
              return <InstallmentOrderComplete orderInfo={orderInfo} />
            default:
              return (
                <div>
                  <Button
                    active={paymentMethod === "card-number"}
                    onClick={() => setPaymentMethod("card-number")}
                  >
                    일반 결제
                  </Button>
                  <Button
                    active={paymentMethod === "pg"}
                    onClick={() => setPaymentMethod("pg")}
                  >
                    할부 결제
                  </Button>
                  {paymentMethod === "card-number" ? (
                    <div>
                      <Card primary>
                        <Form
                          onSubmit={handleSubmit(values => {
                            if (validate(values, cardNumber, expiry)) {
                              setOrderState(1)
                              installment > 1
                                ? createInstallmentOrder(
                                    ...makeOrderData(values)
                                  )
                                : createOrder(...makeOrderData(values))
                            }
                          })}
                        >
                          <CreditCardInputWrap>
                            <div>
                              <div>
                                <InputTopLabel>카드정보</InputTopLabel>
                                <FixedCreditCardInput
                                  cardNumberInputProps={{
                                    value: cardNumber,
                                    onChange: e => {
                                      setCardNumber(e.target.value)
                                      sec = 0
                                    },
                                    onFocus: e => (sec = 0),
                                  }}
                                  cardExpiryInputProps={{
                                    value: expiry,
                                    onChange: e => setExpiry(e.target.value),
                                  }}
                                  cardCVCInputProps={{ value: 123 }}
                                  fieldClassName="checkout-card-input"
                                  customTextLabels={{
                                    invalidCardNumber:
                                      "카드번호가 잘못 되었습니다",
                                    expiryError: {
                                      invalidExpiryDate:
                                        "만료일이 잘못 되었습니다",
                                    },
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  height: "30px",
                                }}
                              />

                              <div>
                                <InputTopLabel>
                                  카드 비밀번호 (앞 2자리)
                                </InputTopLabel>
                                <Field
                                  name="cardpw"
                                  type="password"
                                  width={100}
                                  component={renderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                            </div>

                            <InputTopLabel>
                              카드 소지자 확인 (생년월일 또는 사업자등록번호 -
                              법인카드)
                            </InputTopLabel>
                            <Field
                              name="cardowner"
                              type="number"
                              width={300}
                              nomargin={true}
                              component={renderField}
                              onFocus={e => (sec = 0)}
                            />
                            <CreditSaveInfo>
                              주문하시는 정보는 아임포트 인증서버에 안전하게
                              보관됩니다.
                            </CreditSaveInfo>
                          </CreditCardInputWrap>
                          <div>
                            <div>
                              <Field
                                name="username"
                                type="text"
                                label="주문자 성함"
                                width={150}
                                component={renderField}
                                onFocus={e => {
                                  // startCounter()
                                }}
                                onChange={e => {
                                  if (!fbqLockAddPaymentInfo) {
                                    console.log("value", e.target.value)
                                    // fbq("track", "AddPaymentInfo", {
                                    //   contents: e.target.value,
                                    // })
                                    fbq("track", "AddPaymentInfo")
                                    sec = 0
                                    setFbqLockAddPaymentInfo(true)
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <Field
                                name="phone"
                                type="text"
                                label="전화번호"
                                placeholder="010-1234-5678"
                                width={200}
                                component={renderField}
                                onFocus={e => (sec = 0)}
                              />
                            </div>

                            <div>
                              <Field
                                name="email"
                                type="email"
                                label="이메일 주소"
                                placeholder="email@gmail.com"
                                width={200}
                                nomargin={true}
                                component={renderField}
                                onFocus={e => (sec = 0)}
                              />
                            </div>
                          </div>
                          <InflowFields>
                            <InputTopLabel>
                              알게되신 경로 (중복 체크가능)
                            </InputTopLabel>

                            <div>
                              <div>
                                <Field
                                  name="inflow-fb-page"
                                  type="checkbox"
                                  label="게시물 (페이스북 or 인스타그램)"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                              <div>
                                <Field
                                  name="inflow-fb-ad"
                                  type="checkbox"
                                  label="광고 (페이스북)"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                            </div>

                            <div>
                              <div>
                                <Field
                                  name="inflow-insta-ad"
                                  type="checkbox"
                                  label="광고 (인스타그램)"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>

                              <div
                              // col="7"
                              >
                                <Field
                                  name="inflow-newsletter"
                                  type="checkbox"
                                  label="하버스쿨 뉴스레터"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                                <br />
                                <Field
                                  name="inflow-kakao"
                                  type="checkbox"
                                  label="카카오톡"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                                <br />
                                <Field
                                  name="inflow-friend"
                                  type="checkbox"
                                  label="지인"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                                <br />

                                <Field
                                  name="inflow-etc"
                                  type="checkbox"
                                  label="없음"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                            </div>
                          </InflowFields>

                          <Button type="submit">주문 확정</Button>
                          {error && <ErrorWrapper>{error}</ErrorWrapper>}
                        </Form>
                      </Card>
                    </div>
                  ) : (
                    <div>
                      <Card primary>
                        <Form
                          onSubmit={handleSubmit(values => {
                            if (validatePG(values)) {
                              setOrderState(1)
                              createPGOrder(...makeOrderData(values))
                            }
                          })}
                        >
                          <div>
                            <div>
                              <Field
                                name="username"
                                type="text"
                                label="주문자 성함"
                                width={150}
                                component={renderField}
                                onFocus={e => {
                                  // startCounter()
                                }}
                                onChange={e => {
                                  if (!fbqLockAddPaymentInfo) {
                                    console.log("value", e.target.value)
                                    // fbq("track", "AddPaymentInfo", {
                                    //   contents: e.target.value,
                                    // })
                                    fbq("track", "AddPaymentInfo")
                                    sec = 0
                                    setFbqLockAddPaymentInfo(true)
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <Field
                                name="phone"
                                type="text"
                                label="전화번호"
                                placeholder="010-1234-5678"
                                width={200}
                                component={renderField}
                                onFocus={e => (sec = 0)}
                              />
                            </div>

                            <div>
                              <Field
                                name="email"
                                type="email"
                                label="이메일 주소"
                                placeholder="email@gmail.com"
                                width={200}
                                nomargin={true}
                                component={renderField}
                                onFocus={e => (sec = 0)}
                              />
                            </div>
                          </div>

                          <InflowFields>
                            <InputTopLabel>알게되신 경로</InputTopLabel>
                            <div
                            // height={17}
                            />

                            <div>
                              <div
                              // col="5"
                              >
                                <Field
                                  name="inflow-fb-page"
                                  type="checkbox"
                                  label="페이스북 페이지"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                              <div
                              // col="7"
                              >
                                <div
                                // width={30}
                                // mWidth="0"
                                />
                                <div>
                                  <div
                                  // height="0"
                                  // mHeight="6"
                                  />
                                </div>

                                <Field
                                  name="inflow-fb-ad"
                                  type="checkbox"
                                  label="페이스북 광고"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                            </div>
                            <div
                            // height={20}
                            // mWidth="0"
                            />

                            <div>
                              <div
                              // col="5"
                              >
                                <Field
                                  name="inflow-insta-ad"
                                  type="checkbox"
                                  label="인스타그램 광고"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>

                              <div
                              // col="7"
                              >
                                <div
                                // width={30}
                                //  mWidth="0"
                                />

                                <div>
                                  <div
                                  //  height="0"
                                  // mHeight="6"
                                  />
                                </div>

                                <Field
                                  name="inflow-friend"
                                  type="checkbox"
                                  label="지인"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                                <div
                                // width={36}
                                />

                                <Field
                                  name="inflow-etc"
                                  type="checkbox"
                                  label="기타"
                                  component={checkoutRenderField}
                                  onFocus={e => (sec = 0)}
                                />
                              </div>
                            </div>
                          </InflowFields>

                          <Button type="submit">주문 시작</Button>
                          {error && <ErrorWrapper>{error}</ErrorWrapper>}
                        </Form>
                      </Card>
                    </div>
                  )}
                </div>
              )
          }
        })()}
      </div>
    </Wrap>
  )
}

export const CheckoutForm = reduxForm({
  form: "CHECKOUT_FORM",
})(CheckoutFormComp)
