import * as React from "react"
import styled, { css } from "styled-components"
import Countdown from "react-countdown-now"
import {
  OPENSALE_PRICE,
  EARLYBIRD_PRICE,
  SUPER_EARLYBIRD_PRICE,
  ORIGINAL_PRICE,
  CURRENT_PERIOD,
  SUPER_EARLYBIRD_LEFT_AMOUNT,
  EARLYBIRD_LEFT_AMOUNT,
  OPENSALE_LEFT_AMOUNT,
} from "../../shared/classConsts/hello-framer-x"
import { commaPipe } from "ruucm-util"
import { colors } from "../../shared/consts"
import { motion, transform } from "framer-motion"
import useStore from "../../datas/useStore"
const Center = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const Wrap = styled.div`
  border-radius: 6px;
  white-space: nowrap;
  background: white;
  padding: 45px 30px;
`
const Price = styled.div`
  font-family: NanumSquare;
  font-weight: 800;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.support01};
  position: relative;
`
const InstallmentInfo = styled.div`
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`
const DiscountInfo = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
  row-gap: 6px;
`
const DiscountTitle = styled.div`
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: -0.04em;
  color: #000000;
`
const DiscountAmount = styled.div`
  font-family: NanumSquare;
  font-weight: 800;
  font-size: 22px;
  line-height: 25px;
  color: ${colors.support01};
  text-align: right;
  position: relative;
`
const DiscountBeforeAfter = styled.div`
  position: absolute;
  right: 0;
  top: calc(-100% - 4px);
`
const DiscountBefore = styled.span`
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2);
  text-decoration: line-through;
`
const DiscountAfter = styled.span`
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 7px;
`
const Variation = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
`
const CountdownWrap = styled.div`
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: -0.04em;
  color: #000000;
  text-align: right;
  margin-top: 15px;
`
const CountdownEmoji = styled.span``
const CountdownTitle = styled.span`
  margin-left: 6px;
`
const CountdownClock = styled.span`
  margin-left: 12px;
  color: ${colors.support01};
`
// component
export const PriceInfo = ({ scrollAmount }) => {
  const [classOption, setClassOption] = useStore()
  const discountedTotal = classOption.total - classOption.couponDiscount

  return (
    <Center>
      <Wrap>
        <Price>
          {`월 ${commaPipe(discountedTotal / 6)} 원`}
          <InstallmentInfo>6개월 할부시</InstallmentInfo>
        </Price>
        <DiscountInfo
          style={{
            y: transform(scrollAmount, [270, 370], [50, 0]),
            opacity: transform(scrollAmount, [270, 370], [0, 1]),
          }}
        >
          <DiscountTitle>{CURRENT_PERIOD.title}</DiscountTitle>
          <DiscountAmount>
            <DiscountBeforeAfter>
              <DiscountBefore>{commaPipe(ORIGINAL_PRICE)}</DiscountBefore>
              <DiscountAfter>{commaPipe(discountedTotal)}</DiscountAfter>
            </DiscountBeforeAfter>
            {`-${commaPipe(ORIGINAL_PRICE - CURRENT_PERIOD.price)}원`}
          </DiscountAmount>
          {classOption.couponDiscount > 0 && (
            <>
              <DiscountTitle>쿠폰 할인</DiscountTitle>
              <DiscountAmount>
                {`-${commaPipe(classOption.couponDiscount)}원`}
              </DiscountAmount>
            </>
          )}
        </DiscountInfo>
        <Variation>
          {/* <PriceVariation
            price={SUPER_EARLYBIRD_PRICE - classOption.couponDiscount}
            leftAmount={SUPER_EARLYBIRD_LEFT_AMOUNT}
            active={CURRENT_PERIOD.price === SUPER_EARLYBIRD_PRICE}
          /> */}
          <PriceVariation
            price={EARLYBIRD_PRICE - classOption.couponDiscount}
            leftAmount={EARLYBIRD_LEFT_AMOUNT}
            active={CURRENT_PERIOD.price === EARLYBIRD_PRICE}
          />
          {/* <PriceVariation
            price={OPENSALE_PRICE - classOption.couponDiscount}
            leftAmount={OPENSALE_LEFT_AMOUNT}
            active={CURRENT_PERIOD.price === OPENSALE_PRICE}
          /> */}
        </Variation>
      </Wrap>
      <CountdownWrap>
        <CountdownEmoji>⏰</CountdownEmoji>
        <CountdownTitle>할인 마감</CountdownTitle>
        <CountdownClock>
          <Countdown date={CURRENT_PERIOD.deadLine} />
        </CountdownClock>
      </CountdownWrap>
    </Center>
  )
}
const PriceVariationWrap = styled.div`
  text-align: center;
  margin-top: 70px;
  opacity: 0.2;
  ${({ active }) =>
    active &&
    css`
      opacity: 0.4;
    `}
`
const PriceAmount = styled.div`
  font-family: NanumSquare;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
  color: #000000;
`
const AvailableAmount = styled.div`
  font-family: NanumSquare;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
`
const PriceVariation = ({ price, leftAmount, active }) => {
  return (
    <PriceVariationWrap active={active}>
      <PriceAmount>{`${price / 10000}만원`}</PriceAmount>
      <AvailableAmount>
        {leftAmount ? `(${leftAmount}자리 남음)` : `전량 매진`}
      </AvailableAmount>
    </PriceVariationWrap>
  )
}
