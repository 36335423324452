import React from "react"
import { useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as System from "../../design-system"
import Image from "../components/image"
import StaticImage from "../components/static-image"
import {
  CURRENT_NUMBER_OF_THE_PRODUCTS,
  ORIGINAL_PRICE,
  framerCouponDeadline,
  surpriceCouponDeadline,
  CURRENT_PERIOD,
  hfOpened,
} from "../shared/classConsts/hello-framer-x"
import { isClient, isMobile } from "../shared/utils"
import { breakPoint, colors } from "../shared/consts"
import useStore from "../datas/useStore"
import { AnimatePresence, motion, AnimateSharedLayout } from "framer-motion"
import { commaPipe } from "ruucm-util"

const FullWidthHero = styled.div`
  background: ${colors.ui01};
  width: 100vw;
  min-height: 960px;
  margin-left: calc((-100vw + 1080px) / 2);
  padding: 200px 0;
  @media (max-width: ${breakPoint}px) {
    width: 100vw;
    min-height: 300px;
    margin-left: -24px;
    padding: 150px 20px;
  }
`

const HfImage = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

const ReviewImage = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

const MapImage = styled.div`
  max-width: 600px;
`
const DesktopWrap = styled.div`
  @media (max-width: ${breakPoint}px) {
    display: none;
  }
`
const MobileWrap = styled.div`
  display: none;
  @media (max-width: ${breakPoint}px) {
    display: block;
  }
`

var PurchaseArea

const Page = () => {
  // const guard = Guard()
  // if (guard && isClient) navigate("/")
  if (typeof window !== "undefined") {
    PurchaseArea = require("../components/PurchaseArea").default
  }
  const [classOption, setClassOption] = useStore()
  const [currentVideo, setCurrentVideo] = useState(1)

  return (
    <Layout>
      <SEO
        title="[온라인] Hello, Framer X 정규과정"
        description="욕심 많은 디자이너를 위한. 작업속도를 2배로 빠르게 해주는. Framer X 강의 | Hello, Framer X 8기"
        metaImage={require("../images/hf8-1-min.png")}
      />

      {(() => {
        switch (currentVideo) {
          case 0:
            return (
              <>
                <DesktopWrap>
                  <System.PageTitleDefault
                    h1={"'이직 준비중' 디자이너 근영님이 말하는"}
                  />
                </DesktopWrap>
                <MobileWrap>
                  <System.PageTitleDefault
                    h1={"'이직 준비중' 디자이너<br/>근영님이 말하는"}
                  />
                </MobileWrap>
              </>
            )
          case 1:
            return (
              <>
                <DesktopWrap>
                  <System.PageTitleDefault
                    h1={"'현직 디자이너' 디자이너 현수님이 말하는"}
                  />
                </DesktopWrap>
                <MobileWrap>
                  <System.PageTitleDefault
                    h1={"'현직 디자이너' 디자이너<br/>현수님이 말하는"}
                  />
                </MobileWrap>
              </>
            )

          case 2:
            return (
              <>
                <DesktopWrap>
                  <System.PageTitleDefault
                    h1={"'주니어 디자이너' 디자이너 지원님이 말하는"}
                  />
                </DesktopWrap>
                <MobileWrap>
                  <System.PageTitleDefault
                    h1={"'주니어 디자이너' 디자이너<br/>지원님이 말하는"}
                  />
                </MobileWrap>
              </>
            )

          default:
            return "Add Valid currentVideo"
        }
      })()}
      {/* <MobileWrap> */}
      <System.Spacing />
      {/* </MobileWrap> */}
      <System.PageTitle
        h1="하버스쿨 Hello, Framer X"
        animating
        currentVideo={currentVideo}
      />

      <System.Spacing height={90} />
      <System.VideoPlaylist
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
      />
      <System.Spacing height={90} />
      <FullWidthHero
        style={{
          marginTop: -77,
        }}
      >
        <HfImage>
          <Image filename="hf-vertical.png" />
        </HfImage>
        <System.HighlightImageText text="욕심 많은 디자이너를 위한." />
        <System.HighlightImageText text="작업속도를 2배로 빠르게." />
      </FullWidthHero>
      {(() => {
        switch (currentVideo) {
          case 0:
            return <ContentGeunyoung />
          case 1:
            return <ContentHyunsu />
          case 2:
            return <ContentJiwon />

          default:
            return "Add Valid currentVideo"
        }
      })()}

      <System.Spacing />
      <System.HeroText
        titleText="이 툴."
        subTitleText="기존의 상식을 엎어 버렸습니다"
      >
        <System.Paragraph
          text="저같이 욕심 많은 디자이너에게는<br />
        다른 툴들은 한계가 분명하더라구요"
          color="white"
        />
        <System.Spacing height={20} />
        <System.Paragraph
          text="항상 툴을 사용하다 보면, 이런 걸 구현하고 싶은데"
          color="white"
        />
        <System.Paragraph
          text="툴 자체에서 지원이 안 되는 경우가 많았습니다"
          color="white"
        />
      </System.HeroText>

      <System.Spacing />

      <System.Paragraph text="이러면 유저들은 <strong>툴에서 업데이트가 될 때까지 무한정 기다려야 하죠</strong>" />
      <System.Paragraph text="심지어 간단한 가로 스크롤이나, 벡터 포맷이 지원 안 되는 툴들도 있었어요" />
      <System.Spacing />

      <System.Paragraph text="또 어느 정도 디테일은 잡았지만," />
      <System.Paragraph text="이것을 Pinch Zoom 같이 다양한 유저의 인터렉션과 결합하자니" />
      <System.Paragraph text="<strong>좀 정확하게 표현하기가 어려운 경우도</strong> 있었어요" />
      <System.Heading2Default h2="UI 그릴 시간도 부족한데...<br />프로토 타이핑할 시간은 다들 있으신가요?" />
      <System.Paragraph text="사내에서 아직 '프로토 타이핑' 부서가 따로 있는 곳은 많지 않습니다" />
      <System.Paragraph text="대부분 UI 디자이너 혼자서 꽤 많은 것을 감당해야 하는 곳도 있죠" />
      <System.Spacing />

      <System.Paragraph text="하지만 <strong>항상 시간상 이유로 프로토타입 형태를 구현하지 못하는 게 아쉬웠어요</strong>" />
      <System.Spacing />

      <System.Paragraph text="회사에서 UI 그리는 것만 하여도 시간은 부족하지만" />
      <System.Paragraph text="프로토 타입을 만들면," />
      <System.Paragraph text="사용자 경험을 개발 단계 전에 빠르게 테스트해볼 수 도 있고" />
      <System.Paragraph text="인터렉션을 효과적으로 제안하기도 쉽거든요" />
      <System.Heading1Default h1="아무나 쓸 수 없는, 저만 알고 싶은<br/>Framer X를 소개합니다." />
      {/* <Image filename="hf3-x.gif"/> */}
      <StaticImage source={require("../images/hf3-5.gif")} caption="" />
      <System.Heading2Default h2="이 툴로 만들 수 없는 것은 없습니다" />
      <System.Paragraph text="Framer X는 표준 리액트로 만들어졌습니다" />
      <System.Paragraph text="웹 API와, HTML, CSS를 직접 사용해 프로토 타입을 보여줍니다" />
      <System.Spacing />

      <System.Paragraph text="이 기술들을 한 다리 걸쳐놓고 사용하는 것과는 <strong>본질적으로 다르죠</strong>" />
      <System.Spacing />

      <System.Paragraph text="그냥 <strong>개발자들의 기술을 직접적으로 쉽게 사용하실 수 있다</strong>고 생각하시면 됩니다." />
      <System.QuoteDefault text="이 툴로 만들 수 없다는 것은<br />개발이 불가능하다는 말 과도 같습니다" />

      <System.HeroText
        titleText="누구보다 빠르고 효율적으로 '일' 하세요"
        subTitleText="비슷한 걸 만들 때마다, 같은 작업을 반복할 필요는 없죠"
      >
        <System.Paragraph
          text="Framer X를 쓰면 <strong>반복 작업을 정말 많이 줄여주어</strong>"
          color="white"
        />
        <System.Paragraph
          text="훨씬 작업시간을 단축시켜 줍니다"
          color="white"
        />
        <System.Spacing height={20} />

        <System.Paragraph
          text="<strong>쓰면 쓸수록 작업 속도가 빨라지죠</strong>"
          color="white"
        />
      </System.HeroText>

      <System.Spacing />

      <System.Paragraph text="이 반복 작업을 Framer X 스토어에 올리면 전 세계 사람들과 공유할 수 있고" />
      <System.Paragraph text="이를 사내 Private 저장소에서만 공유한다면," />
      <System.Paragraph text="그 유명한 <strong>'디자인 시스템'</strong>이라는 것을 만드실 수 도 있죠" />
      <System.Spacing />
      <System.Image
        renderedImg={<Image filename="hf3-6-min.png" />}
        caption="Framer Store. 전 세계 모든 사람들이 자기가 만든 인터렉션들을 쉽고 빠르게 공유하는 마켓"
      />
      <System.Image
        renderedImg={<Image filename="hf3-7-min.png" />}
        caption="팀 내부에서만 컴포넌트를 공유할 수 있는 Team Store"
      />

      <System.Heading1Default
        h1="하지만<br />코드 기반이기에 아무에게나 
        <br />추천하지 않습니다"
      />
      <System.Paragraph text="실제로 우리가 많이 쓰던 포토샵, 일러스트 같은 툴 같은 " />
      <System.Paragraph text="직관적인 인터페이스와 <strong>코드는 다릅니다</strong>" />
      <System.Spacing />

      <System.Paragraph text="Hello, Framer X에서는" />
      <System.Paragraph text="Javscript, HTML, CSS의 기본적인 지식을 같이 알려 주시고" />
      <System.Paragraph text="이를 Framer X에서 꽤 빈번히 사용하더라고요" />
      <System.Spacing />

      <System.Paragraph text="이에 아래와 같은 디자이너분들에게는<br /> <strong>추천하지 않습니다</strong>" />
      <System.Paragraph text="1.  나는 디자이너여서, <strong>코드에 평생 손 묻히기 싫다면</strong>" />
      <System.Paragraph text="2.  코드를 배워 보았지만, 정말 <strong>코드가 끔찍한 트라우마로</strong> 남았다면" />
      <System.Paragraph text="3.  어차피 그냥 보고용이니 <strong>적당히 구현하고 끝내는 것이 좋다면</strong>" />
      <System.QuoteDefault text="하지만 디자이너로써 <strong>디테일에 욕심</strong>이 있으시고<br />누구보다 <strong>빠르고 효율적으로 일을</strong> 하고 싶으신 디자이너 라면<br /><br /><strong>Hello, Framer X 강의를 자신 있게 추천합니다</strong>" />
      <System.HeroText
        titleText="Hello, Framer X 8기"
        subTitleText="배우고 싶었는데, 너무 최신의 툴이라 한국어로 된 자료가 없었어요"
      />
      <System.QuoteDefault text="핫하다. 혁명적이다. 게임 체인저다.<br /><br />너무 궁금해서 한번 깊이 배워 보고는 싶었지만<br />초기의 툴이라 그런지 <strong>한국어로 된 자료가 많이 없었어요</strong><br /><br />저도 유튜브나 몇 가지 강의들을 봤지만 잘 이해도 안 되고<br />대부분의 영상들이 <strong>겉핥기식</strong>으로 접근해서 <strong>실제 업무에 적용하기는 어려웠죠</strong>" />
      <System.Heading2Default h2="635 시간 동안 만든 커리큘럼" />
      <Image filename="hf3-8-min.png" />
      <StaticImage source={require("../images/hf3-9.gif")} caption="" />
      {/* <Image filename="hf3-10-min.png" /> */}
      <System.Paragraph text="이 강의는 루움 강사님이" />

      <System.Paragraph text="Framer X의 출시일부터 엄청난 업데이트에 속도에 맞추어" />
      <System.Paragraph text="<strong>600여 시간에 걸쳐</strong> 지속적으로 <strong>업그레이드</strong> 해왔습니다." />

      <System.Spacing />
      <System.Paragraph text="이를 바탕으로한 저희 커리큘럼에는 Framer X에 관한<br/><strong>다른 곳에서는 찾아 볼 수 없는 체계적인 위키 시스템과, 과제들이</strong>포함 되어있습니다" />

      <System.Spacing />

      <System.Paragraph text="8기에는" />
      <System.Paragraph text="여태껏 받았던 질문들과, 수강생분들의 학습 속도들" />
      <System.Paragraph text="피드백들을 반영하여" />
      <System.Paragraph text="더욱 개선되고 탄탄해진 <strong>Hello, FramerX (v5) 커리큘럼</strong>이 제공됩니다." />
      <br />
      <a
        href="https://www.notion.so/115cafe76cba4a7d923a5de05d180b90?v=5eedc257078945f2b362eb9f5a318e10"
        target="_blank"
        style={{
          textDecoration: "none",
        }}
      >
        <System.Paragraph text="<strong>📕📗 커리큘럼 자세히 보기 👈</strong>" />
      </a>
      <System.Heading1Default h1="Framer X에 빠져버린<br />루움 강사님 이야기" />
      <Image filename="hf3-11-min.png" />
      {/* <System.Paragraph text="평소 학교 공부에 큰 만족감을 느끼지 못하던" /> */}
      <System.Paragraph text="저 루움은 <strong>실리콘 밸리 Ecole 42</strong>에서 Computer Programming을 공부하고 돌아왔습니다" />
      <System.Spacing />

      <System.Paragraph text="평소 외국의 웹사이트들을 보며" />
      <System.Paragraph text="이런 웹사이트들은 도대체 어떻게 만드는 것일까" />
      <System.Paragraph text="아시는 분들은 다 아는 지용님, 종민님등 이런 분들의 작업들을 보며" />
      <System.Spacing />

      <System.Paragraph text="여러 애니메이션 요소들을 만들기도 하고, 많은 라이브러리들을 탐구하며 " />
      <System.Paragraph text="많은 덕질을 해왔습니다" />
      <System.Paragraph text="하지만 이런 많은 덕질에도 저를 강하게 만족시켜주는 도구들은 없었던 것 같아요" />
      <System.Spacing />

      <System.Paragraph text="그러던 중 우연치 않은 기회로 Framer X를 접하게 되고" />
      <System.Paragraph text="제가 좋아하던 모든 고민들을 <strong>이 툴 하나로 완전히 해결할 수 있다는 것을 깨달았습니다</strong>" />
      <System.Spacing />

      <System.Paragraph text="이에 <strong>비슷한 고민</strong>을 하시는 분들이 많다는 걸 깨달았고" />
      <System.Paragraph text="<strong>Framer X를 출시일부터 지금까지 거의 매일 써보며</strong>" />
      <System.Paragraph text="이를 더 많은 분들이 쓸 수 있으면 너무 좋겠다는 생각에" />
      <System.Paragraph text="이 강의를 열게 되었습니다" />
      <StaticImage
        source={require("../images/hf3-12.gif")}
        caption='최근 루움님이, 처음부터 끝까지 Framer X로 작업한 실제 챗봇 앱의 데모 사이트 - <a href="https://pulsen.netlify.com/?token=demo" target="_blank">https://pulsen.netlify.com/?token=demo</a>'
      />
      <System.Heading1Default h1="Hello, Framer X 8기.<br />후기로 설명드리겠습니다" />
      {/* <Image filename="hf3-13-min.png" />
      <Image filename="hf3-14-min.png" />
      <Image filename="hf3-15-min.png" /> */}
      <ReviewImage>
        <Image filename="reviews2.png" />
      </ReviewImage>

      <System.HeroText
        titleText="Hello, Framer X 8기"
        subTitleText="<br />이미 검증된 Framer X 강의를, 온라인으로 안전하게 들으시고<br /><br />디자이너의 욕심을 더는 감추지 마세요"
      />

      <System.Heading2Default h2="강의 채널" />
      <System.Paragraph text="Zoom 라이브를 사용한 쌍방향 온라인 수업" />
      <System.Paragraph
        text="이 강의는 녹화된 영상들과 다르게 

<strong>실시간으로 진행</strong>됩니다 


이에 듣게 되시는 수강생 분들이 

어렵거나 모르는 부분에 대한 <strong>개별적인 

질문과 답</strong>을 하며 <strong>루움 강사님과 직접적으로 소통</strong>하며 배울 수 있습니다 "
      />
      <br />
      {/* <MapImage>
        <Image filename="zoom-live-0.png" />
      </MapImage> */}
      <StaticImage source={require("../images/webinar.gif")} caption="" />

      <System.Heading2Default h2="강의 녹화본 제공" />
      <System.Paragraph text="강의 녹화본을 등록하신 분들에게 모두 제공합니다.<br/>이에 수업시간에 미처 따라 오시기 힘들었던 내용들을, <strong>원하시는 시간에 충분히</strong> 복습할 수 있습니다" />
      <br />
      <Image filename="record.png" />

      <System.Heading2Default h2="지금 구매 시, Framer 50% 할인권 제공" />
      <System.Paragraph text="Framer 본사와 직접적인 제휴로 하버스쿨 강의 등록시, <strong>Framer Pro 플랜을 연간 50% 할인</strong> 받아 이용하실 수 있는 할인권을 제공 드립니다" />
      <br />

      {classOption && (
        <System.Heading2Default
          h2={`가격 및 시간<br/>
              <strike>정가 ${ORIGINAL_PRICE /
                10000}만 원</strike> -> ${(classOption.total -
            classOption.couponDiscount) /
            10000}만 원<br/>
              <strong>- ${CURRENT_PERIOD.title} / 월 ${commaPipe(
            (classOption.total - classOption.couponDiscount) / 6
          )} <span>(6개월 할부 시)</span></strong>`}
        />
      )}

      <System.Paragraph text="시간 : 구매 버튼 클릭시, 선택하실 수 있으신 시간 옵션이 나타납니다" />

      <System.Heading2Default h2="<strong>(하단 노란색 구매버튼 클릭)</strong>" />
      <br />

      <br />

      <System.Spacing />
      <System.Spacing />
      <FullWidthHero>
        <div
          style={{
            maxWidth: 1080,
            margin: "0 auto",
          }}
        >
          <System.Heading1Default h1="Hello, Framer X 강의에 대해 많이들<br />궁금해하시는 부분들을 모았습니다" />
          <System.Heading2Default h2="Q. 제가 코딩은 완전 처음인데.. 이 강의를 들어도 괜찮을까요?" />
          <System.Paragraph
            text="이 수업을 들으셨던 분들의 <strong>92% 이상이 디자이너 분들이셨습니다.</strong><br />
          그렇기에 처음 코드를 접하시는 <strong>초보자 분들의 특성을 최대한 고려하여</strong><br />
          커리큘럼과 수업이 설계되어 있습니다"
          />
          <System.Spacing />

          <System.Paragraph text="또한 중간에 지속적으로 배웠던 내용을 반복하는 세션을 통해<br /><strong>자연스럽게 코드와 친해지시게</strong> 됩니다" />
          <System.Heading2Default h2="Q. 리액트 / 타입 스크립트를 개발자처럼 배워야 하는 건가요?" />
          <System.Paragraph text="<strong>아닙니다.</strong>" />
          <System.Spacing />

          <System.Paragraph text="기초반에서 다루는 인터렉션들은" />
          <System.Paragraph text="애니메이션을 위한 Override의 반복되는 규칙을 활용합니다." />
          <System.Paragraph text="그렇기에 <strong>리액트 / 타입 스크립트에 대한 이해 없이도</strong>" />
          <System.Spacing />

          <System.Paragraph text="충분히 수업을 따라오실 수 있습니다." />

          <System.Heading2Default h2="Q. 회사일이 바빠서 평일 저녁 수업은 조금 부담이 되네요" />
          <System.Paragraph text="전 커리큘럼은 저희 위키 시스템을 통해 지속적으로 업데이트가 되고<br /><strong>언제든 무료로 받아 보실 수 있습니다</strong><br /><br /><br />또한 중간중간 내드리는 과제와, 복습 세션<br />원하시는 시간대에 공부와 질문답을 하시고<br /><br /><br />수업 때 그 공부하신 내용을 극대화하는 방향으로 수업이 진행됩니다<br /><br /><br />실제로 야근 때문에 수업을 두세 번 빠지신 분들도<br />수업을 성공적으로 따라오셨습니다<br />하지만 아시겠지만 빠지면 손해이십니다" />
          <System.Heading2Default h2="Q. 신청했는데, 불가피한 사정 때문에 환불해야 할 것 같습니다" />
          <System.Paragraph text="일단 신청하신 후, 모집 마감 5일 전까지 100% 환불 가능하십니다<br />하지만 그 이후로는 환불이 불가함을 알려드립니다" />

          <System.Heading2Default h2="Q. class101 강의와 어떤 차이가 있나요?" />
          <System.Paragraph text="class101 강의는 보다 더 가볍게 Framer 를 시작할 수 있는 강의 입니다. 좀 더 가볍게 Framer 를 맛보고 싶으시면 <a href='https://link.harbor.school/class101-framer' target='_blank'>class101에 개설된 강의를</a> 추천 드립니다" />

          <System.Heading2Default h2="Q. 중급반은 어떻게 들을 수 있나요?" />
          <System.Paragraph
            text="많은 분들이 중급반 강의를 원하시고 계십니다<br /><br /><br />하지만 중급반은 <strong>
기초반을 수강하신 분들에게만</strong>
<br />별도로 안내가 됩니다 (10월 안내 예정)<br /><br /><br />기초반을 신청하실시 <strong>
우선적으로 중급반 결제가 안내</strong>
되오니<br />참고 부탁드립니다"
          />

          <System.Heading2Default h2="Q. Framer 어떤 플랜을 이용하나요?" />
          <System.Paragraph text="본 강의는 코드를 직접적으로 다루기에 Framer Desktop 을 주로 이용 합니다. 이에 Free / Starter 플랜을 사용하셔도 되지만, Pro 플랜을 구매 해주시는 것을 권장 드립니다" />
        </div>
      </FullWidthHero>

      <System.Spacing />
      <System.FbLike />

      {/* <System.Heading1Default h1="이 역대급 툴 Framer X를<br />더 좋은 가격에 배울 수 있는 '단 한 가지' 방법" />
      <System.Heading2Default h2="혜택" />
      <System.Paragraph text="<strong>총 10분</strong>에게<br/><strong>Framer X 50% 할인권</strong> + <strong>Hello, Framer X 8기 10만 원 캐시백</strong> 제공" />
      <System.Heading2Default h2="대상" />
      <System.Paragraph text="<strong>구매 여부 상관없이</strong><br/>아래 형식으로 참여해주신 <strong>모든 분들</strong> (이미 구매하신 분은 10만 원 그대로 돌려 드립니다)" />
      <System.Heading2Default h2="기간" />
      <System.Paragraph text="<strong>~ 7/31</strong> 일 자정 전까지" />
      <System.Heading2Default h2="발표" />
      <System.Paragraph text="8/1일에 <strong>페이스북 메시지</strong>로 개별적으로 연락드립니다" />
      <System.Heading2Default h2="참여 방법" />
      <System.Paragraph text="1. 아래 버튼을 눌러서, <strong>하버스쿨 페이지 좋아요</strong>" />
      <System.FbLike />
      <System.Paragraph text="2. 아래 버튼을 눌러서 <strong>한 줄 기대평</strong> + <strong>#HelloFramerX8기</strong> 해시태그와 함께<br/> 페이스북에 <strong>'전체 공개'</strong>로 공유 (관심 있을 법한 친구 같이 태그 하시면 당첨 확률 업!)" />
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=http://link.harbor.school/t89"
        target="_blank"
      >
        Hello, Framer X 8기 오픈 소식 공유하기
      </a>
      <System.Spacing  />
      <System.Paragraph text="1) 게시물 비공개 혹은 친구 공개 설정 시<br />2) 기대평 + 해시태그 미삽입 시 당첨이 취소될 수 있습니다" /> */}
      {typeof window !== "undefined" && <PurchaseArea />}
      {/* <PurchaseArea /> */}

      {hfOpened && (
        <AnimateSharedLayout>
          <motion.div animate>
            <AnimatePresence>
              {classOption.showCouponNotification && (
                <System.NotificationPanel>
                  <System.Notification
                    id={0}
                    title="깜짝 추가 할인 쿠폰 6만원"
                    em="👉 더블 클릭하여 쿠폰 적용하기"
                    time={surpriceCouponDeadline}
                    handleOnClick={() => {
                      let discountAmount = 60000
                      setClassOption({
                        couponDiscount: 60000,
                      })
                      alert(
                        `${discountAmount /
                          10000}만원 쿠폰 할인이 적용 되었습니다`
                      )
                      if (typeof window !== "undefined" && window.fbq != null)
                        window.fbq("trackCustom", "CouponDiscount")
                    }}
                  />
                  <System.Notification
                    id={1}
                    title="지금 구매하면"
                    em="Framer 연간 50% 할인코드 증정"
                    time={framerCouponDeadline}
                    hideOnMobile
                  />
                </System.NotificationPanel>
              )}
            </AnimatePresence>
          </motion.div>
        </AnimateSharedLayout>
      )}
    </Layout>
  )
}

export default Page

const ContentGeunyoung = () => {
  return (
    <>
      <System.Heading1Default
        h1="이직 준비 하는데<br/>
    하버스쿨 수업이 도움 되었어요"
      />
      {/* <System.Paragraph text="굉장히 도움이 되었어요" /> */}
      <System.Spacing />
      <System.Paragraph text="<strong>이직 시장에서는</strong> 자주" />
      <System.Paragraph text="프로토타입을 하는 역량에 대해서" />
      <System.Paragraph text="우대하는 상황이 되게 많아요" />
      <System.Spacing />
      <System.Paragraph text="내가 프로토타입을 할 줄 안다" />
      <System.Paragraph text="vs 그냥 안다" />
      <System.Paragraph text="좀 <strong>차이가 있었던 것</strong> 같아요" />

      <System.Heading1Default h1="프로토 타이핑을 '그냥 안다'와<br/>'할 줄 안다'는 다릅니다" />
      <System.Paragraph text="제가 그 전에는 프로토타입을 알지~ " />
      <System.Paragraph text="이 정도는 뭐 할 수 있겠지 였었는데" />
      <System.Spacing />
      <System.Paragraph text="<strong>하버 스쿨 수업을</strong> 듣고 나서는" />
      <System.Paragraph text="이제 좀 당당하게" />
      <System.Paragraph text="어, 나 이것 할줄 알아 " />
      <System.Paragraph text="나 이 정도 만들 수 있어" />
      <System.Spacing />
      <System.Paragraph text="그러니까 좀 어디 가서도" />
      <System.Paragraph text="이게 좀 <strong>자신감으로 바뀌는 거죠</strong>" />
      <System.Image
        renderedImg={<Image filename="hf3-2.png" />}
        caption="수많은 프로토타이핑 툴들 (Prototypr — All Prototyping Tools)"
      />
    </>
  )
}

const ContentHyunsu = () => {
  return (
    <>
      <System.Heading1Default h1="N사 4년 차 UI/UX 디자이너 이야기" />
      <System.Paragraph
        text="UI 시안 그리는 것도 손이 모자란데<br />
    주변의 다른 사람들은 어느 순간부터 '프로토 타이핑' 이라는 것을 
    하기 시작하더군요"
      />
      <System.Spacing />
      <System.Paragraph text="그래서 저도 이것저것 알아보니 <strong>왜 이리 또 프로토타이핑 툴의 종류는 많은 건지</strong>" />
      <System.Paragraph text="대게의 디자이너들처럼 <strong>처음에는 막막하기만 했던</strong> 평범한 4년 차 프로덕트 디자이너 이현수입니다." />
      <System.Image
        renderedImg={<Image filename="hf3-2.png" />}
        caption="수많은 프로토타이핑 툴들 (Prototypr — All Prototyping Tools)"
      />
      <System.Paragraph text="저는 어쨌든 한번 작업을 시작하면" />
      <System.Paragraph text=" <strong>작은 디테일</strong>까지 신경을 써야 하는 <strong>욕심 많은 성격의 디자이너라</strong>" />
      <System.Paragraph text="이것저것 써보았는데 그렇게 맘에 차는 것은 없더군요" />
      <System.Spacing />

      <System.Image
        renderedImg={<Image filename="hf3-3-min.png" />}
        caption="현수님의 작업물 Music Album App 중"
      />
      <System.Paragraph text="그러던 중 우연히 하버 스쿨에서 하는 Framer X 강의를 광고로 보게 되었고" />
      <System.Paragraph text="회사의 지원으로 <strong>Hello, Framer X 기초반과 중급반</strong>을 듣게 되었습니다." />
      <System.QuoteDefault text="네. 그리고 지금은<br/>하루 종일 Framer X 만 씁니다" />
      <System.Paragraph text="강의를 듣기 전 까진 이것이 <strong>제가 하는 일을 이토록 완전히 바꿀 줄은</strong> 몰랐어요" />
    </>
  )
}

const ContentJiwon = () => {
  return (
    <>
      <System.Heading1Default
        h1="인터렉션 협업 툴<br/>
    Framer X"
      />
      <System.Spacing />
      <System.Paragraph text="<strong>회사 우대사항에</strong> 프레이머가 있더라고요." />
      <System.Paragraph text="UI 쪽에서는 어쨌든 제플린이 협업의 중간 툴이잖아요" />
      <System.Spacing />
      <System.Paragraph text="근데 인터렉션 쪽에서는" />
      <System.Paragraph text="Framer가 그 역할을 하려고 하는 것 같아서" />
      <System.Paragraph text="좀 <strong>차이가 있었던 것</strong> 같아요" />

      <System.Spacing />
      <System.Paragraph text="내가 원하는 인터렉션을 <strong>개발자에게 정확히 전달하려면.</strong>" />
      <System.Paragraph text="프레이머를 배워야 겠다고 생각했어요" />

      <System.Heading1Default h1="수강 후 얻은 것" />
      <System.Paragraph text="옛날에는 진짜 정적인" />
      <System.Paragraph text="프로토타입을 만들었으면" />
      <System.Paragraph text="이제는 <strong>동적인 것도 할 수 있게</strong> 되었어요" />
      <System.Spacing />
      <System.Paragraph text="수강 후 인터렉션의 사용성도 분명히 생각해볼 수 있게 되어서 만족했습니다" />
      <System.Spacing />
      <System.Paragraph text="제가 포트폴리오도 준비할 거거든요" />
      <System.Paragraph text="확실히 제가 모션 같은 것도 구현하면" />
      <System.Paragraph text="<strong>저만의 무기가</strong> 될 수 있지 않을까 생각해요" />

      <System.Spacing />
      {/* <System.Image
        renderedImg={<Image filename="hf3-2.png" />}
        caption="수많은 프로토타이핑 툴들 (Prototypr — All Prototyping Tools)"
      /> */}
    </>
  )
}
