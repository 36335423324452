import { SubmissionError } from "redux-form"

const validate = values => {
  if (!values.username) {
    throw new SubmissionError({
      _error: "주문자 성함은 필수 필드 입니다",
    })
  }
  if (!values.phone) {
    throw new SubmissionError({
      _error: "전화번호는 필수 필드 입니다",
    })
  }

  if (!values.email) {
    throw new SubmissionError({
      _error: "이메일은 필수 필드입니다",
    })
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    throw new SubmissionError({
      _email: "이메일을 입력하세요",
      _error: "이메일 형식이 잘못 되었습니다",
    })
  }

  if (
    !values["inflow-fb-page"] &&
    !values["inflow-fb-ad"] &&
    !values["inflow-insta-ad"] &&
    !values["inflow-friend"] &&
    !values["inflow-etc"]
  ) {
    throw new SubmissionError({
      _error: "이 클래스를 알게 되신 경로를,\n한개 이상 알려주세요 :)",
    })
  }

  return true
}

export default validate
